<template>
    <div class="wrap">
        <div class="search-box">
            <el-input placeholder="输入关键词搜索" v-model="keyword" class="input-with-select" :clearable=true>
                <!-- <div slot="prepend">
          <el-select :value="cls.name" placeholder="请选择" @change="setCls">
            <el-option v-for="item in clsArr" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select>
        </div> -->
                <el-button slot="append" icon="el-icon-search" @click.stop="getData(1)"></el-button>
            </el-input>
        </div>
        <dyList :list="list"></dyList>
        <div class="page-box" v-show="total">
            <el-pagination :pager-count="5" :page-size="20" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
    </div>
</template>
<script>
    import dyList from "@components/common/dy-list";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            dyList,
        },
        data() {
            return {
                list: [],
                keyword: "",
                total: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1);
        },
        methods: {
            setCls(m) {
                this.cls = {
                    id: m.id,
                    name: m.name,
                };
                this.getData(1);
            },
            changePage(s) {
                this.getData(s);
            },
            // 获取列表
            async getData(page) {
                let param = {
                    longitude: this.$y_getKey("lon"),
                    latitude: this.$y_getKey("lat"),
                    type: 0, // 动态类型
                    user_id: this.$y_getKey("userid") || 8,
                    keyword: this.keyword,
                };
                param.page = page || 1;
                let {
                    list,
                    total
                } = await this.$y_list("api/dymanic/lists", [], param);
                this.list = list;
                if (total != -1) {
                    this.total = total;
                }
            },
        },
        computed: {
            ...mapGetters(["s_getUser", "s_getCity"]),
        },
        watch: {
            s_getUser() {
                this.getData(1);
            },
            s_getCity() {
                this.getData(1);
            },
        },
    };
</script>

<style lang="less" scoped>
    .search-box {
        padding-top: 15px;

        /deep/.el-input__inner {
            padding-top: 5px;
            padding-bottom: 5px;
            height: auto;
            min-width: 100px;
        }
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .search-box {
            margin: 0 15px;

            /deep/.el-input__inner {
                padding-top: 0px;
                padding-bottom: 0px;
                min-width: 80px;
            }
        }
    }
</style>